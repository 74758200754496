import '../css/index.css';
import './custom/highlight';

import { Elm } from '../src/Main.elm';


Elm.Main.init({
  node: document.querySelector('main'),
});

